import React from "react";
import classNames from "classnames";
import { SectionTilesProps } from "../../utils/SectionProps";
import SectionHeader from "./partials/SectionHeader";
import Image from "../elements/Image";
import { Grid, Paper } from "@mui/material";
import { Divider } from "@material-ui/core";
import { Link } from "react-router-dom";

const propTypes = {
  ...SectionTilesProps.types,
};

const defaultProps = {
  ...SectionTilesProps.defaults,
};
const FeaturesTiles = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  pushLeft,
  ...props
}) => {
  const outerClasses = classNames(
    "features-tiles section",
    topOuterDivider && "has-top-divider",
    bottomOuterDivider && "has-bottom-divider",
    hasBgColor && "has-bg-color",
    invertColor && "invert-color",
    className
  );

  const innerClasses = classNames(
    "features-tiles-inner section-inner pt-0",
    topDivider && "has-top-divider",
    bottomDivider && "has-bottom-divider"
  );

  const sectionHeader = {
    title: "The Features of Blockchains",
    paragraph:
      "Business-to-consumer lean startup incubator iPad growth hacking influencer research & development user experience.",
  };

  return (
    <Grid container className={outerClasses}>
      <div className="container">
        <div className={innerClasses}>
          <SectionHeader data={sectionHeader} className="center-content" />

          <div style={{ display: "flex", flexWrap: "wrap" }}>
            <Paper style={{ padding: "5px", width: "40%" }} xs={12} md={6}>
              <Grid className="ta-c">
                <h4>Token Pre-Sale</h4>
                <p>Maximize your token sale reward</p>
                <Divider />
              </Grid>

              <div style={{ padding: "10px" }}>
                <h2 style={{ color: "red", textAlign: "center" }}>
                  Token expired
                </h2>
                <Link
                  to="#0"
                  className="button button-primary button-wide-mobile button-sm full-width w-100"
                  // onClick="{}"
                >
                  Join
                </Link>
              </div>
            </Paper>
            <Paper
              style={{
                padding: "10px",
                display: "inline-flex",
                flexWrap: "wrap",
                width: "50%",
              }}
              xs={12}
              md={6}
            >
              <ul>
                <li>
                  <div style={{ display: "inline-flex" }}>
                    <Image
                      src={require("../../assets/images/img1.svg")}
                      alt="Watch Video"
                      style={{ paddingRight: "5px" }}
                    />
                    Security Assured
                  </div>
                </li>
                <li>
                  <div style={{ display: "inline-flex" }}>
                    <Image
                      src={require("../../assets/images/img2.svg")}
                      alt="Watch Video"
                      style={{ paddingRight: "5px" }}
                    />
                    Send & Receive
                  </div>
                </li>
                <li>
                  <div style={{ display: "inline-flex" }}>
                    <Image
                      src={require("../../assets/images/img3.svg")}
                      alt="Watch Video"
                      style={{ paddingRight: "5px" }}
                    />
                    Buy, Sell, & Exchange
                  </div>
                </li>
                <li>
                  <div style={{ display: "inline-flex" }}>
                    <Image
                      src={require("../../assets/images/img4.svg")}
                      alt="Watch Video"
                      style={{ paddingRight: "5px" }}
                    />
                    Access Anywhere
                  </div>
                </li>

                <li>
                  <div style={{ display: "inline-flex" }}>
                    <Image
                      src={require("../../assets/images/img5.svg")}
                      alt="Watch Video"
                      style={{ paddingRight: "5px" }}
                    />
                    Secure like Forte
                  </div>
                </li>
                <li>
                  <div style={{ display: "inline-flex" }}>
                    <Image
                      src={require("../../assets/images/img6.svg")}
                      alt="Watch Video"
                      style={{ paddingRight: "5px" }}
                    />
                    Easy Cash-out
                  </div>
                </li>
                <li>
                  <div style={{ display: "inline-flex" }}>
                    <Image
                      src={require("../../assets/images/img7.svg")}
                      alt="Watch Video"
                      style={{ paddingRight: "5px" }}
                    />
                    Easy to Setup
                  </div>
                </li>
                <li>
                  <div style={{ display: "inline-flex" }}>
                    <Image
                      src={require("../../assets/images/img8.svg")}
                      alt="Watch Video"
                      style={{ paddingRight: "5px" }}
                    />
                    Daily backups 24/7
                  </div>
                </li>
              </ul>
            </Paper>
          </div>
        </div>
      </div>
    </Grid>
  );
};

FeaturesTiles.propTypes = propTypes;
FeaturesTiles.defaultProps = defaultProps;

export default FeaturesTiles;

import React from "react";
import classNames from "classnames";
import { Link } from "react-router-dom";
import { SectionSplitProps } from "../../utils/SectionProps";
import {
  Paper,
  Box,
  Button,
  CardContent,
  Card,
  Typography,
  CardActions,
  CardMedia,
  CardActionArea,
  IconButton,
  Grid,
  Container,
} from "@mui/material";

import SimplePieChart from "../elements/graphics/PieChart";
import VerticalChart from "../elements/graphics/VerticalComposedChart";

const FeaturesSplit2 = () => {
  return (
    <Container
      sx={{ display: "flex", flexWrap: "wrap", padding: "60px 0px 60px 0px" }}
    >
      <Grid item xs={12} md={8} sx={{ display: "flex", fontSize: "12px" }}>
        <Paper elevation={3} sx={{ margin: "15px" }}>
          <VerticalChart />
        </Paper>
        <Paper elevation={3} sx={{ margin: "15px" }}>
          <SimplePieChart />
        </Paper>
      </Grid>
      <Grid item xs={12} md={4}>
        <Box elevation={3}>
          <Typography variant="h5" gutterBottom component="div">
            Rewarding entertainment for you and your loved ones
          </Typography>
          <Typography
            variant="subtitle1"
            gutterBottom
            component="div"
            sx={{ padding: "25px 0px" }}
          >
            Deployment long tail monetization strategy equity basic of
            conversion. Supply chain freemium investor long tail agile
            development prototype validation influencer.
          </Typography>
          <Link
            to="#0"
            className="button button-primary button-wide-mobile button-sm "
            // onClick="{}"
          >
            Learn More
          </Link>
        </Box>
      </Grid>
    </Container>
  );
};

export default FeaturesSplit2;

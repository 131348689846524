import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { SectionProps } from "../../utils/SectionProps";
import ButtonGroup from "../elements/ButtonGroup";
import Button from "../elements/Button";
import Image from "../elements/Image";

const propTypes = {
  ...SectionProps.types,
  split: PropTypes.bool,
};

const defaultProps = {
  ...SectionProps.defaults,
  split: false,
};

const Cta = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  split,
  ...props
}) => {
  const outerClasses = classNames(
    "cta section center-content-mobile reveal-from-bottom",
    topOuterDivider && "has-top-divider",
    bottomOuterDivider && "has-bottom-divider",
    hasBgColor && "has-bg-color",
    invertColor && "invert-color",
    className
  );

  const innerClasses = classNames(
    "cta-inner section-inner",
    topDivider && "has-top-divider",
    bottomDivider && "has-bottom-divider",
    split && "cta-split"
  );

  return (
    <section {...props} className={outerClasses}>
      <div>
        <div className={innerClasses}>
          <div className="cta-slogan">
            <h4 className="m-0 textWhite">Ready to get started?</h4>
            <p className="textWhite">
              30 days trial - Power up your workflow with webify toolkit
            </p>
          </div>
          <div className="cta-action">
            <div className="reveal-from-bottom" data-reveal-delay="600">
              <ButtonGroup>
                <Button tag="a" color="primary" wideMobile href="#">
                  Download Whitepaper
                </Button>
                <Button tag="a" color="white" wideMobile>
                  Talk to Sales
                </Button>
              </ButtonGroup>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

Cta.propTypes = propTypes;
Cta.defaultProps = defaultProps;

export default Cta;

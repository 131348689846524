import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { SectionProps } from "../../utils/SectionProps";
import ButtonGroup from "../elements/ButtonGroup";
import Button from "../elements/Button";
import Image from "../elements/Image";

const propTypes = {
  ...SectionProps.types,
  split: PropTypes.bool,
};

const defaultProps = {
  ...SectionProps.defaults,
  split: false,
};

const Cta2 = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  split,
  ...props
}) => {
  const outerClasses = classNames(
    "cta section center-content-mobile reveal-from-bottom",
    topOuterDivider && "has-top-divider",
    bottomOuterDivider && "has-bottom-divider",
    hasBgColor && "has-bg-color",
    invertColor && "invert-color",
    className
  );

  const innerClasses = classNames(
    "cta-inner section-inner",
    topDivider && "has-top-divider",
    bottomDivider && "has-bottom-divider",
    split && "cta-split"
  );

  return (
    <section {...props} className={(outerClasses, { marginTop: "70px" })}>
      <div>
        <div className={innerClasses}>
          <div className="cta-slogan">
            <h5 className="m-0 textWhite">
              Power up your workflow with Webify multi-purpose wordpress theme.
            </h5>

            <Button tag="a" color="primary" wideMobile href="#">
              Download Whitepaper
            </Button>
          </div>
          <div className="cta-action">
            <div className="reveal-from-bottom" data-reveal-delay="600">
              <Image
                src={require("../../assets/images/mobile.png")}
                alt="Watch Video"
                style={{ position: "relative", right: "100px", top: "-70px" }}
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

Cta2.propTypes = propTypes;
Cta2.defaultProps = defaultProps;

export default Cta2;

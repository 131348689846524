import React from "react";
import classNames from "classnames";
import { Link } from "react-router-dom";
import { SectionSplitProps } from "../../utils/SectionProps";
import {
  Paper,
  Box,
  Button,
  CardContent,
  Card,
  Typography,
  CardActions,
  CardMedia,
  CardActionArea,
  IconButton,
  Grid,
  Container,
} from "@mui/material";
import FavoriteBorderOutlinedIcon from "@mui/icons-material/FavoriteBorderOutlined";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import LocalAtmOutlinedIcon from "@mui/icons-material/LocalAtmOutlined";
import SectionHeader from "./partials/SectionHeader";
import Image from "../elements/Image";
import Graphic from "../elements/graphics/Graphic";

const FeaturesSplit = () => {
  return (
    <Container
      sx={{ display: "flex", flexWrap: "wrap", padding: "60px 0px 60px 0px" }}
    >
      <Grid item xs={12} md={6}>
        <Box elevation={3}>
          <Typography variant="h5" gutterBottom component="div">
            70.3% of all tokens will be made available to everyone
          </Typography>
          <Typography
            variant="subtitle1"
            gutterBottom
            component="div"
            sx={{ padding: "25px 0px" }}
          >
            Wollo Token Sale Starts 5th September at Midday|Educating children
            about the future of money|World’s first token sale with tangible
            reward today.
          </Typography>
          <Link
            to="#0"
            className="button button-primary button-wide-mobile button-sm "
            // onClick="{}"
          >
            DOWNLOAD WHITEPAPER
          </Link>
        </Box>
      </Grid>
      <Grid item xs={12} md={6}>
        <Paper elevation={3}>
          <Graphic />
        </Paper>
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <Card sx={{ maxWidth: 345, marginTop: "30px" }}>
            <CardActionArea
              sx={{
                display: "flex",
                flexDirection: "column",
                flexWrap: "wrap",
                alignContent: "center",
                justifyContent: "center",
                width: "180px",
                height: "150px",
              }}
            >
              <FavoriteBorderOutlinedIcon
                sx={{ fontSize: "70px", color: "blue" }}
              />
              <CardContent>
                <Typography variant="body2" color="text.secondary">
                  1M Customers
                </Typography>
              </CardContent>
            </CardActionArea>
          </Card>
          <Card sx={{ maxWidth: 345, marginTop: "30px" }}>
            <CardActionArea
              sx={{
                display: "flex",
                flexDirection: "column",
                flexWrap: "wrap",
                alignContent: "center",
                justifyContent: "center",
                width: "180px",
                height: "150px",
              }}
            >
              <LocalAtmOutlinedIcon sx={{ fontSize: "70px", color: "blue" }} />
              <CardContent>
                <Typography variant="body2" color="text.secondary">
                  Key Currencies
                </Typography>
              </CardContent>
            </CardActionArea>
          </Card>
          <Card sx={{ maxWidth: 345, marginTop: "30px" }}>
            <CardActionArea
              sx={{
                display: "flex",
                flexDirection: "column",
                flexWrap: "wrap",
                alignContent: "center",
                justifyContent: "center",
                width: "180px",
                height: "150px",
              }}
            >
              <LockOutlinedIcon sx={{ fontSize: "70px", color: "blue" }} />
              <CardContent>
                <Typography variant="body2" color="text.secondary">
                  Layered Security
                </Typography>
              </CardContent>
            </CardActionArea>
          </Card>
        </Box>
      </Grid>
    </Container>
  );
};

export default FeaturesSplit;
